// 使用示例
import { request } from "@/utils/request"

const apiList = {
	// 公共
	common: {
		login: {
			url: "/addons/chatgpt/user/qrcode",
			method: "GET",
			desc: "公众号登录"
		},
		logout: {
			url: "/addons/chatgpt/user/logout",
			method: "GET",
			desc: "退出登录"
		},
		verify: {
			url: "/addons/chatgpt/user/verify",
			method: "GET",
			timeout: 1000 * 100,
			desc: "验证授权登录"
		},
		index: {
			url: "/addons/chatgpt/web/index",
			method: "GET",
			desc: "首页数据"
		},
		statistic: {
			url: "/addons/chatgpt/web/index",
			method: "GET",
			desc: "统计数据"
		},
		init: {
			url: "/addons/chatgpt/index/init",
			method: "GET",
			desc: "初始化"
		},
		upload: {
			url: "/addons/chatgpt/index/upload",
			method: "POST",
			desc: "上传图片"
		}
	},
	// 案例
	case: {
		classify: {
			url: "/addons/chatgpt/archives/cotegory",
			method: "GET",
			desc: "分类"
		},
		list: {
			url: "/addons/chatgpt/archives/index",
			method: "GET",
			desc: "列表"
		},
		detail: {
			url: "/addons/chatgpt/archives/show",
			method: "GET",
			desc: "详情"
		}
	},
	// 用户
	user: {
		info: {
			url: "/addons/chatgpt/user/index",
			method: "GET",
			desc: "用户信息"
		}
	},
	// 对话
	chat: {
		create: {
			url: "/addons/chatgpt/group/saveGroup",
			method: "GET",
			desc: "新建会话"
		},
		delete: {
			url: "/addons/chatgpt/group/delGroup",
			method: "GET",
			desc: "删除会话"
		},
		list: {
			url: "/addons/chatgpt/group/getGroupList",
			method: "GET",
			desc: "会话列表"
		},
		history: {
			url: "/addons/chatgpt/web/history",
			method: "GET",
			desc: "聊天历史记录"
		},
		sendText: {
			url: "/addons/chatgpt/web/sendText",
			method: "fetch",
			desc: "获取数据流"
		}
	},
	// 创作
	write: {
		first: {
			url: "/addons/chatgpt/write/topic",
			method: "GET",
			desc: "一级分类"
		},
		second: {
			url: "/addons/chatgpt/write/prompts",
			method: "GET",
			desc: "二级分类"
		},
		detail: {
			url: "/addons/chatgpt/write/prompt",
			method: "GET",
			desc: "分类详情"
		},
		collectList: {
			url: "/addons/chatgpt/write/vote_prompts",
			method: "GET",
			desc: "收藏的二级分类列表"
		},
		collect: {
			url: "/addons/chatgpt/write/vote_prompt",
			method: "GET",
			desc: "收藏/取消收藏二级分类"
		},
		record: {
			url: "/addons/chatgpt/web/history_write",
			method: "get",
			desc: "创作记录"
		}
	},
	// 角色
	role: {
		first: {
			url: "/addons/chatgpt/rule/topic",
			method: "get",
			desc: "一级分类"
		},
		second: {
			url: "addons/chatgpt/rule/prompts",
			method: "GET",
			desc: "二级分类"
		},
		detail: {
			url: "/addons/chatgpt/rule/prompt",
			method: "GET",
			desc: "分类详情"
		},
		collectList: {
			url: "/addons/chatgpt/rule/vote_prompts",
			method: "GET",
			desc: "收藏的二级分类列表"
		},
		collect: {
			url: "/addons/chatgpt/rule/vote_prompt",
			method: "GET",
			desc: "收藏/取消收藏二级分类"
		},
		create: {
			url: "/addons/chatgpt/web/rule_group",
			method: "get",
			desc: "创建角色会话"
		},
		history: {
			url: "/addons/chatgpt/web/history_rule",
			method: "get",
			desc: "角色历史记录"
		}
	},
	// 分销
	place: {
		data: {
			url: "/addons/chatgpt/user/userData",
			method: "get",
			desc: "个人数据"
		},
		team: {
			url: "/addons/chatgpt/reseller/user",
			method: "get",
			desc: "团队"
		},
		brokerage: {
			url: "/addons/chatgpt/reseller/log",
			method: "get",
			desc: "分销记录"
		}
	},
	// 充值
	recharge: {
		integralList: {
			url: "/addons/chatgpt/usable/index",
			method: "get",
			desc: "积分类别"
		},
		memberList: {
			url: "/addons/chatgpt/vip/index",
			method: "get",
			desc: "会员类别"
		},
		dealerList: {
			url: "/addons/chatgpt/reseller/index",
			method: "get",
			desc: "经销商类别"
		},
		createIntegralOrder: {
			url: "/addons/chatgpt/usable/recharge",
			method: "post",
			desc: "创建积分充值订单"
		},
		createMemberOrder: {
			url: "/addons/chatgpt/vip_order/recharge",
			method: "post",
			desc: "创建会员订单"
		},
		createDealerOrder: {
			url: "/addons/chatgpt/reseller/recharge",
			method: "post",
			desc: "创建经销商订单"
		},
		pay: {
			url: "/addons/chatgpt/pay/prepay",
			method: "post",
			desc: "拉起支付"
		},
		queryPayStatus: {
			url: "/addons/chatgpt/pay/checkPay",
			method: "post",
			desc: "查询支付状态"
		}
	},
	// 绘图
	drawing: {
		first: {
			url: "/addons/chatgpt/draw/prompts",
			method: "GET",
			desc: "绘图分类"
		},
		config: {
			url: "/addons/chatgpt/draw/prompt_config",
			method: "GET",
			desc: "配置表"
		},
		create: {
			url: "/addons/chatgpt/draw/text2img",
			method: "POST",
			desc: "生成图片"
		},
		result: {
			url: "/addons/chatgpt/draw/draw_result",
			method: "GET",
			desc: "绘图结果"
		},
		collect: {
			url: "/addons/chatgpt/draw/draw_image_vote",
			method: "GET",
			desc: "点赞或收藏"
		},
		record: {
			url: "/addons/chatgpt/draw/user_draw",
			method: "GET",
			desc: "绘图记录"
		},
		atlas: {
			url: "/addons/chatgpt/draw/draw_images",
			method: "GET",
			desc: "展览图集"
		},
		keyword: {
			url: "/addons/chatgpt/draw/key_words",
			method: "GET",
			desc: "描述关键词"
		},
		template: {
			url: "/addons/chatgpt/draw/prompt_words",
			method: "GET",
			desc: "描述词模板"
		},
		matter: {
			url: "/addons/chatgpt/draw/attachment",
			method: "GET",
			desc: "上传的本地素材库"
		},
		picture: {
			url: "/addons/chatgpt/draw/img2img",
			method: "POST",
			desc: "以图生图"
		}
	}
}

// 组装接口路径
const getApiPath = path => {
	let apiArray = path.split("."),
		api = apiList
	apiArray.forEach(v => {
		api = api[v]
	})
	return api
}

function http(path, data) {
	const api = getApiPath(path)
	if (!api) throw `接口未定义, 已阻止此次API请求`
	const method = api.method.toLowerCase()
	if (method != "get" && method != "post" && method != "fetch") throw `${method} 请求方式未定义, 已阻止此次API请求`
	return request[method](api.url, data, api.timeout)
}

export { apiList, http }
