import { http } from "@/api/api"

export default {
	namespaced: true,
	state: {
		config: ""
	},
	getters: {
		config: state => state.config
	},
	mutations: {
		setConfig(state, data) {
			state.config = data
		}
	},
	actions: {
		// 获取用户信息
		async getConfigInfo({ commit, dispatch, getters, state }) {
			const result = await new Promise((resolve, reject) => {
				http("common.init", { platform: "Web" })
					.then(res => {
						if (res.code === 1) {
							document.title = res.data.system.name
							commit("setConfig", res.data.system)
							resolve(res)
						} else {
							reject(res)
						}
					})
					.catch(err => {
						reject(err)
					})
			})
			return result
		}
	}
}
